// Import des pages de style
import "./Erreur404.css";
import "./Erreur404Mobile.css";

// Import des hooks et composants
import { Link } from "react-router-dom";

// Création de la fonction composant d'erreur
function Erreur404() {
  
  return (
    <>
      <div className="erreur404">
      <div className="astronaute" id="astronaute">
        <img className="astronaute-img"  src=".\images\astronaute.png"/>
        <p className="astronaute-bulle"> AaaAAhhAhhaa !!!</p>
      </div>
        <h3>404</h3>
        <p>Perdu·e dans l'espace ?</p>
        <p>La page que vous demandez n'existe pas.</p>
        <Link  to="/">Retourner sur la page d'accueil</Link>
      </div>
    </>
  );
}



// Export de la fonction
export default Erreur404;