import { useEffect, useState } from "react";

// Import des données
import {
  getMediaData,
  deleteMediaData,
  createMediaData,
  updateMediaData,
} from "../axios/axiosMedias";

// Import des fonctions génériques
import {
  deleteItem,
  handleAddItem,
  handleUpdateItem,
} from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES MEDIAS

export const useMediaState = () => {
  //______________________________________________________
  // Affichage des médias
  const [mediaArray, setMediaArray] = useState([]);
  const [editModalMediaIsOpen, setEditModalMediaIsOpen] = useState(false);

  const fetchMediaData = async () => {
    const mediaData = await getMediaData();
    setMediaArray(mediaData);
  };

  useEffect(() => {
    fetchMediaData();
  }, []);

  //______________________________________________________
  // Suppression d'un média
  const deleteMedia = async (mediaId, mediaNom) => {
    deleteItem(mediaId, `le média ${mediaNom}`, deleteMediaData, setMediaArray);
  };

  //______________________________________________________
  // Ajout d'un média
  const [nomMedia, setNomMedia] = useState("");
  const [imageMediaUrl, setImageMediaUrl] = useState("");
  const [altMediaText, setAltMediaText] = useState("");
  const [descriptionMedia, setDescriptionMedia] = useState("");
  const [lienMedia, setLienMedia] = useState("");
  const [titreLienMedia, setTitreLienMedia] = useState("");

  const handleAddMedia = (e) => {
    handleAddItem(
      e,
      {
        nom: nomMedia,
        image: imageMediaUrl,
        alt: altMediaText,
        description: descriptionMedia,
        lien: lienMedia,
        titreLien: titreLienMedia,
      },
      setMediaArray,
      () => {
        setNomMedia("");
        setImageMediaUrl("");
        setAltMediaText("");
        setDescriptionMedia("");
        setLienMedia("");
        setTitreLienMedia("");
      },
      createMediaData
    );
  };

  //______________________________________________________
  // Modification d'un media
  const [editedMedia, setEditedMedia] = useState({
    nom: "",
    image: "",
    alt: "",
    description: "",
    lien: "",
    titreLien: "",
  });

  const openEditMediaModal = (media) => {
    setEditedMedia(media);
    setEditModalMediaIsOpen(true);
  };

  const closeEditMediaModal = () => {
    setEditModalMediaIsOpen(false);
    setEditedMedia({
      nom: "",
      image: "",
      alt: "",
      description: "",
      lien: "",
      titreLien: "",
    });
  };

  const handleUpdateMedia = (e) => {
    handleUpdateItem(
      e,
      editedMedia,
      updateMediaData,
      closeEditMediaModal,
      fetchMediaData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    mediaArray,
    setMediaArray,
    deleteMedia,
    nomMedia,
    setNomMedia,
    imageMediaUrl,
    setImageMediaUrl,
    altMediaText,
    setAltMediaText,
    descriptionMedia,
    setDescriptionMedia,
    lienMedia,
    setLienMedia,
    titreLienMedia,
    setTitreLienMedia,
    handleAddMedia,
    editModalMediaIsOpen,
    setEditModalMediaIsOpen,
    editedMedia,
    setEditedMedia,
    openEditMediaModal,
    closeEditMediaModal,
    handleUpdateMedia,
  };
};
