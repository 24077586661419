// Import des pages de style
import "./mesConferences.css";
import "./mesConferencesMobile.css";

// Import des données et composants
import { getConfData } from "../../utils/axios/axiosConfs";
import { useState, useEffect } from "react";
import Modal from "react-modal";

// Création de la fonction composant de la galerie de conférences
export default function MesConferences() {

  // Création des variables et fonctions de la gestion de la modale
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedConference, setSelectedConference] = useState(null);
  const [conferences, setConferences] = useState([]);

  // Fonction d'ouverture de la modale
  const openModal = (conference) => {
    setSelectedConference(conference);
    setModalIsOpen(true);
  };

  // Fonction de fermeture de la modale
  const closeModal = () => {
    setSelectedConference(null);
    setModalIsOpen(false);
  };

  // Empêcher le défilement de l'arrière-plan lorsque la modale est ouverte
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalIsOpen]);

  // Récupérer les données des conférences au chargement du composant
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getConfData();
        setConferences(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données des conférences :", error);
      }
    };
    fetchData();
  }, []);

return (
  <div id="pageMesConferences">
    
    <h3>Mes Conférences</h3>
    <p className="presentationConf"> Vous trouverez ici le catalogue des conférences que j’ai déjà construit et proposé à des audiences diverses (grand public, associations, municipalités, festivals, etc…), depuis plusieurs années. N’hésitez pas à explorer tous les sujets présentés ci-dessous et me dire si l’un d’entre eux vous intéresse.</p>
    <p className="presentationConf"> Si vous souhaitez que j’intervienne sur un sujet qui n’est pas présenté ici, n’hésitez pas à me contacter pour que nous en discutions ensemble. </p>

    <div className="boxConf" style={{ filter: modalIsOpen ? "grayscale(100%)" : "none" }}>
      {conferences.map((data) => { // Utilisation des données des conférences récupérées
        return (
          <div className="cardConf" onClick={() => openModal(data)} key={data._id}>
            <h4>{data.nom}</h4>
            <div className="imgBxConf">
              <img src={data.image} alt={data.alt} />
            </div>
            <div className="details">
              <p>Cliquez pour plus d'informations</p>
            </div>
          </div>
        );
      })}
    </div>

      {selectedConference && (
        <div className="modalBackground" onClick={closeModal}>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="customModal" overlayClassName="customOverlay">
            <i className="fa-regular fa-circle-xmark" onClick={closeModal}></i>
            <h4>{selectedConference.nom}</h4>
            <img src={selectedConference.image} alt={selectedConference.alt} />
            <p>{selectedConference.description}</p>
          </Modal>
        </div>
      )}
      
    </div>
  );
}