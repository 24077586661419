import React, { useEffect, useRef } from "react";
import { getLogoData, modifyLogoOrderData } from "../../utils/axios/axiosLogo";
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLogoState } from "../../utils/handleFunctions/handleFunctionsLogo";

export function DnDLogo() {
  const { logoArray, setLogoArray, deleteLogo } = useLogoState();
  const logoOrderRef = useRef(logoArray.map((logo) => logo._id)); // Référence pour l'ordre d'affichage des logos

  useEffect(() => {
    const fetchLogoData = async () => {
      const logoData = await getLogoData();
      const sortedLogoData = logoData.sort((a, b) => a.order - b.order); // Tri des logos par ordre
      setLogoArray(sortedLogoData);
      logoOrderRef.current = sortedLogoData.map((logo) => logo._id); // Mettre à jour la référence de l'ordre d'affichage
    };
    fetchLogoData();
  }, []);

  const moveLogo = (dragIndex, hoverIndex) => {
    const draggedLogoId = logoOrderRef.current[dragIndex];
    const newLogoOrder = [...logoOrderRef.current];
    newLogoOrder.splice(dragIndex, 1);
    newLogoOrder.splice(hoverIndex, 0, draggedLogoId);
    logoOrderRef.current = newLogoOrder; // Mettre à jour la référence de l'ordre d'affichage
  };

  const updateLogoOrder = async () => {
    const newLogoArray = logoOrderRef.current.map((logoId, index) => ({
      ...logoArray.find((logo) => logo._id === logoId),
      order: index,
    }));

    setLogoArray(newLogoArray);

    const success = await modifyLogoOrderData(newLogoArray);
    if (success) {
      console.log("L'ordre des logos a été mis à jour avec succès !");
    } else {
      console.log("Erreur lors de la mise à jour de l'ordre des logos.");
    }
  };

  const LogoDraggable = ({ logo, index }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'logo',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const [, drop] = useDrop(() => ({
      accept: 'logo',
      hover: (item) => {
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        moveLogo(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop: () => {
        updateLogoOrder();
      },
    }));

    const opacity = isDragging ? 0.4 : 1;

    return (
      <div ref={node => drag(drop(node))} style={{ opacity }}>
        <div className="logo-admin">
          <div className="logo-admin-modif">
            <i className="fa-solid fa-trash-can" onClick={() => deleteLogo(logo._id, logo.alt)}></i>
            <i className="fa-solid fa-arrows-up-down-left-right"></i>
          </div>
          <img className="logo_box" src={logo.image} alt={logo.alt} />
        </div>
      </div>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="confiance_logo">
        {logoOrderRef.current.map((logoId, index) => {
          const logo = logoArray.find((logo) => logo._id === logoId);
          return <LogoDraggable key={logo._id} logo={logo} index={index} />;
        })}
      </div>
    </DndProvider>
  );
}
