const dataPrestations = [
  {
    id: "Conferences",
    nom: "CONFÉRENCES",
    image: "./images/mes_prestations/conférences.jpg",
    description: "Mon catalogue de<br/> conférences existantes.",
  },

  {
    id: "Medias",
    nom: "MÉDIAS",
    image: "./images/mes_prestations/medias.jpg",
    description:
      "Télévision, radio, internet... <br/>Voici quelques interventions.",
  },

  {
    id: "GestionDeProjets",
    nom: "GESTION DE PROJETS",
    image: "./images/mes_prestations/gestion de projets et d'evenements.jpg",
    description:
      "Mes services dans l'organistation et l'animation d'évenements.",
  },

  {
    id: "RedactionsScientifiques",
    nom: "RÉDACTIONS SCIENTIFIQUES",
    image: "./images/mes_prestations/redactions scientifiques.jpg",
    description: "Mes rédactions et mes articles, en ligne et sur papier.",
  },
];

export default dataPrestations;
