import { useEffect, useState } from "react";

// Import des données
import {
  getRedactionData,
  deleteRedactionData,
  createRedactionData,
  updateRedactionData,
} from "../axios/axiosRedactions";

// Import des fonctions génériques
import {
  deleteItem,
  handleAddItem,
  handleUpdateItem,
} from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES REDACTIONS

export const useRedactionState = () => {
  //______________________________________________________
  // Affichage des rédactions
  const [redactionArray, setRedactionArray] = useState([]);
  const [editModalRedactionIsOpen, setEditModalRedactionIsOpen] =
    useState(false);

  const fetchRedactionData = async () => {
    const redactionData = await getRedactionData();
    setRedactionArray(redactionData);
  };

  useEffect(() => {
    fetchRedactionData();
  }, []);

  //______________________________________________________
  // Suppression d'un article
  const deleteRedaction = async (redactionId, redactionNom) => {
    deleteItem(
      redactionId,
      `l'article : ${redactionNom}`,
      deleteRedactionData,
      setRedactionArray
    );
  };

  //______________________________________________________
  // Ajout d'une rédaction
  const [nomRedaction, setNomRedaction] = useState("");
  const [imageRedactionUrl, setImageRedactionUrl] = useState("");
  const [altRedactionText, setAltRedactionText] = useState("");
  const [descriptionRedaction, setDescriptionRedaction] = useState("");
  const [lienRedaction, setLienRedaction] = useState("");

  const handleAddRedaction = (e) => {
    handleAddItem(
      e,
      {
        nom: nomRedaction,
        image: imageRedactionUrl,
        alt: altRedactionText,
        description: descriptionRedaction,
        lien: lienRedaction,
      },
      setRedactionArray,
      () => {
        setNomRedaction("");
        setImageRedactionUrl("");
        setAltRedactionText("");
        setDescriptionRedaction("");
        setLienRedaction("");
      },
      createRedactionData
    );
  };

  //______________________________________________________
  // Modification d'une rédaction
  const [editedRedaction, setEditedRedaction] = useState({
    nom: "",
    image: "",
    alt: "",
    description: "",
    lien: "",
  });

  const openEditRedactionModal = (redaction) => {
    setEditedRedaction(redaction);
    setEditModalRedactionIsOpen(true);
  };

  const closeEditRedactionModal = () => {
    setEditModalRedactionIsOpen(false);
    setEditedRedaction({
      nom: "",
      image: "",
      alt: "",
      description: "",
      lien: "",
    });
  };

  const handleUpdateRedaction = (e) => {
    handleUpdateItem(
      e,
      editedRedaction,
      updateRedactionData,
      closeEditRedactionModal,
      fetchRedactionData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    redactionArray,
    setRedactionArray,
    deleteRedaction,
    nomRedaction,
    setNomRedaction,
    imageRedactionUrl,
    setImageRedactionUrl,
    altRedactionText,
    setAltRedactionText,
    descriptionRedaction,
    setDescriptionRedaction,
    lienRedaction,
    setLienRedaction,
    handleAddRedaction,
    editModalRedactionIsOpen,
    setEditModalRedactionIsOpen,
    editedRedaction,
    setEditedRedaction,
    openEditRedactionModal,
    closeEditRedactionModal,
    handleUpdateRedaction,
  };
};
