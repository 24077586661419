// Import des pages de style
import "./meContacter.css";
import "./meContacterMobile.css";

import axios from 'axios';
import { useState } from 'react';

export default function MeContacter() {
  const [nom, setNom] = useState('');
  const [orga, setOrga] = useState('');
  const [email, setEmail] = useState('');
  const [objet, setObjet] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contactData = {
      nom: nom,
      orga: orga,
      email: email,
      objet: objet,
      message: message
    };

    try {
      const response = await axios.post('https://api.astropierre.com/api/contact', contactData);
      setResponseMessage(response.data.message);
      setNom('');
      setOrga('');
      setEmail('');
      setObjet('');
      setMessage('');
    } catch (error) {
      console.error(error);
    }
  };

  // Script pour désactiver le bouton après un clic
  const handleButtonClick = () => {
    const nomField = document.getElementById('nom');
    const emailField = document.getElementById('email');
    const objetField = document.getElementById('objet');
    const messageField = document.getElementById('message');
  
    if (nomField.checkValidity() && emailField.checkValidity() && objetField.checkValidity() && messageField.checkValidity()) {
      // Tous les champs requis sont remplis, masquer le bouton "Envoyer"
      const boutonEnvoyer = document.getElementById('boutonEnvoyer');
      boutonEnvoyer.style.display = 'none';

      const envoiEnCours = document.getElementById('envoiEnCours');
      envoiEnCours.style.display = 'flex';
    }
  };

  return (
    <div id="pageMeContacter">
      <h3>Me contacter</h3>
      {responseMessage ? (
        <div className="reponseMessage">
          <p className="messageEnvoye">{responseMessage}</p>
          <a className="retourAccueil" href="/">Retourner à l'accueil</a>
        </div>
      ) : (
        <>
          <p className="phraseContact">Que vous sachiez précisément ce que vous souhaitez ou que vous ayez un projet à co-construire, n’hésitez pas à me contacter via ce formulaire. Je me ferai un plaisir d’en discuter avec vous afin d’adapter mes services à vos besoins. </p>

          <form className="formContact" onSubmit={handleSubmit}>
            <label>Nom<input type="text" name="nom" id="nom" value={nom} onChange={(e) => setNom(e.target.value)} required /></label>
            <label>Organisation / entreprise <input type="text" name="organisation" id="organisation" value={orga} onChange={(e) => setOrga(e.target.value)} /></label>
            <label>Email<input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required /></label>
            <label>Objet du message<input type="objet" name="objet" id="objet" value={objet} onChange={(e) => setObjet(e.target.value)} required /></label>
            <label>Message<textarea name="message" id="message" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea></label>
            <label id="boutonEnvoyer"><input className="boutonEnvoyer" type="submit" value="Envoyer" id="envoyerButton" onClick={handleButtonClick} /></label>
            <div id="envoiEnCours" style={{ display: 'none' }}>
                <p id="messageEnCours">Message en cours d'envoi, veuillez patienter.</p>
                <i id="spinner" className="fa-solid fa-spinner"></i>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
