import "./login.css";
import React, { useRef } from "react";
import axios from "axios";

export default function Login() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = async (event) => {
    event.preventDefault();
  
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
  
    try {
      const response = await axios.post("https://api.astropierre.com/api/auth/login", {
        email,
        password,
      });
  
      const token = response.data.token;
      const userId = response.data.userId; // Nouvelle ligne pour extraire le userId
  
      // Stocker le token et le userId dans le localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId); // Nouvelle ligne pour stocker le userId
  
      // Redirection vers la page "/dashboard"
      window.location.href = "/dashboard";
    } catch (error) {
      // Gérer les erreurs de la requête
      console.error("Erreur lors de la connexion :", error);
    }
  };
  

  return (
    <div id="pageDeLogin">
      <h3>Se connecter</h3>
      <form className="formContact" onSubmit={handleLogin}>
        <label>
          Identifiant
          <input type="email" name="email" id="email" required ref={emailRef} />
        </label>
        <label>
          Mot de passe
          <input type="password" name="mdp" id="mdp" required ref={passwordRef} />
        </label>
        <label id="boutonConnecter">
          <input className="boutonConnecter" type="submit" value="Se connecter" />
        </label>
      </form>
    </div>
  );
}
