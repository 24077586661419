// Import des pages de style
import "./dashboard.css";

// Import des besoins
import ReactModal from 'react-modal';
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Erreur404 from "../../components/Erreur404/Erreur404";

// Import des fonctions de gestion des états
import { useLogoState } from "../../utils/handleFunctions/handleFunctionsLogo";
import { useConfState } from "../../utils/handleFunctions/handleFunctionsConf";
import { useMediaState } from "../../utils/handleFunctions/handleFunctionsMedia";
import { useProjetState } from "../../utils/handleFunctions/handleFunctionsProjet";
import { useRedactionState } from "../../utils/handleFunctions/handleFunctionsRedaction";

// Import du DnD
import { DnDLogo } from "../../components/DnD/DnDLogo"

// Création de la page
export default function Dashboard() {

  //______________________________________________________
  // GESTION DE LA DECONNEXION
  const handleLogout = () => {
    const confirmLogout = window.confirm("Tu es sûr d'avoir terminé ?");
    if (confirmLogout) {
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  };

  //______________________________________________________
  // GESTION DE LA MODALE PRINCIPALE
  ReactModal.setAppElement('#root');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);

  const openModal = (modal) => {
    setSelectedModal(modal);
    setModalIsOpen(true);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Gestion des formulaires d'ajout
  const [displayForm, setDisplayForm] = useState(false);
  const handleToggleForm = () => {
    setDisplayForm(!displayForm);
  };


  //______________________________________________________
  // GESTION DES LOGOS
  const { logoArray, deleteLogo, imageUrl, setImageUrl, altText, setAltText, handleAddLogo } = useLogoState();
  
  const [logoModalIsOpen, setLogoModalIsOpen] = useState(false);

  const openLogoModal = () => {
    setLogoModalIsOpen(true);
  };

  const closeLogoModal = () => {
    setLogoModalIsOpen(false);
  };
  


  // GESTION DES CONFERENCES
  const { confArray, deleteConf, nomConf, setNomConf, imageConfUrl, setImageConfUrl, altConfText, setAltConfText, descriptionConf, setDescriptionConf, handleAddConf, editModalConfIsOpen, editedConf, setEditedConf, openEditModal, closeEditModal, handleUpdateConf } = useConfState();

  // GESTION DES MEDIAS
  const { mediaArray, deleteMedia, nomMedia, setNomMedia, imageMediaUrl, setImageMediaUrl, altMediaText, setAltMediaText, descriptionMedia, setDescriptionMedia, lienMedia, setLienMedia, titreLienMedia, setTitreLienMedia, handleAddMedia, editModalMediaIsOpen, editedMedia, setEditedMedia, openEditMediaModal, closeEditMediaModal, handleUpdateMedia } = useMediaState();

  // GESTION DES PROJETS
  const { projetArray, deleteProjet, nomProjet, setNomProjet, imageProjetUrl, setImageProjetUrl, altProjetText, setAltProjetText, descriptionProjet, setDescriptionProjet, lienProjet, setLienProjet, handleAddProjet, editModalProjetIsOpen, editedProjet, setEditedProjet, openEditProjetModal, closeEditProjetModal, handleUpdateProjet } = useProjetState();

  // GESTION DES REDACTIONS
  const { redactionArray, deleteRedaction, nomRedaction, setNomRedaction, imageRedactionUrl, setImageRedactionUrl, altRedactionText, setAltRedactionText, descriptionRedaction, setDescriptionRedaction, lienRedaction, setLienRedaction, handleAddRedaction, editModalRedactionIsOpen, editedRedaction, setEditedRedaction, openEditRedactionModal, closeEditRedactionModal, handleUpdateRedaction } = useRedactionState();

  
  //______________________________________________________
  // Contenus des différentes modales de modifications
  const renderModalContent = () => {
    switch (selectedModal) {

      case 'logos des partenaires':
        return (
          <>
            <div className="add"onClick={handleToggleForm}>
              <i className="fa-solid fa-user-plus"></i>
              <p>Ajouter un logo</p>
            </div>
            
            <form className="form-modal" style={{ display: displayForm ? 'flex' : 'none' }} onSubmit={handleAddLogo}>
              <label> URL de l'image 
                <input type="text" name="image" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)}required/>
              </label>
              <label> Texte alternatif
                <input type="text"  name="alt" value={altText}  onChange={(e) => setAltText(e.target.value)}required/>
              </label>
              <button className="boutonAjouter" type="submit"> Ajouter à la base de donnée </button>
              <button onClick={handleToggleForm}> Annuler </button>
            </form>
            <div className="add" onClick={openLogoModal}>
              <i className="fa-solid fa-pen-to-square"></i>
              <p>Modifier l'ordre des logos</p>
            </div>
            <div className="confiance_logo">
              {logoArray.sort((a, b) => a.order - b.order).map((logo) => (
                <div className="logo-admin" key={logo._id}>
                  <div className="logo-admin-modif">
                    <i className="fa-solid fa-trash-can" onClick={() => deleteLogo(logo._id, logo.alt)}></i>
                  </div>
                  <img className="logo_box" src={logo.image} alt={logo.alt} />
                </div>
              ))}
            </div>

              {logoModalIsOpen && (
              <div className="edit-modal">
                    <div className="edit-modal--header">
                      <h1>Modification de l'ordre des logos</h1> 
                      <i className="fa-regular fa-circle-xmark" onClick={closeLogoModal}></i>
                    </div>
                    <DnDLogo/>
              </div>
            )}
          </>
        );
    
        case 'conférences':
            return (
              <>
                <div className="add" onClick={handleToggleForm}>
                  <i className="fa-solid fa-user-plus"></i>
                  <p>Ajouter une conférence</p>
                </div>
                <form className="form-modal" style={{ display: displayForm ? 'flex' : 'none' }} action="submit" method="post" onSubmit={handleAddConf}>
                  <label>Titre de la conférence<input type="text" name="nom" value={nomConf} onChange={(e) => setNomConf(e.target.value)} required /></label>
                  <label>URL de l'image<input type="text" name="image" value={imageConfUrl} onChange={(e) => setImageConfUrl(e.target.value)} required /></label>
                  <label>Texte alternatif<input type="text" name="alt" value={altConfText} onChange={(e) => setAltConfText(e.target.value)} required /></label>
                  <label>Description<textarea type="text" name="description" value={descriptionConf} onChange={(e) => setDescriptionConf(e.target.value)} required /></label>
                  <button className="boutonAjouter" type="submit"> Ajouter à la base de donnée </button>
                  <button onClick={handleToggleForm}> Annuler </button>
                </form>
                <div className="conf-modal">
                  {confArray.map((conf) => (
                    <div className="conf-admin" key={conf._id}>
                      <div className="logo-admin-modif">
                        <i className="fa-solid fa-trash-can" onClick={() => deleteConf(conf._id, conf.nom)}></i>
                        <i className="fa-solid fa-arrows-up-down-left-right"></i>
                        <i className="fa-solid fa-file-pen" onClick={() => openEditModal(conf)}></i>
                      </div>
                      <h4 className="conf-title">{conf.nom}</h4>
                      <img className="img-admin" src={conf.image} alt={conf.alt} />
                    </div>
                  ))}
                </div>

                {editModalConfIsOpen && (
                  <div className="edit-modal">
                    <div className="edit-modal--header">
                      <h1>Modification de la conférence "{editedConf.nom}"</h1> 
                      <i className="fa-regular fa-circle-xmark" onClick={closeEditModal}></i>
                    </div>
                    <form onSubmit={handleUpdateConf}>
                      <label>Titre de la conférence</label>
                      <input type="text" name="nom" value={editedConf.nom} onChange={(e) => setEditedConf({ ...editedConf, nom: e.target.value })} required />  
                      <label>URL de l'image</label>
                      <input type="text" name="image" value={editedConf.image} onChange={(e) => setEditedConf({ ...editedConf, image: e.target.value })} required />
                      <label>Texte alternatif</label>
                      <input type="text" name="alt" value={editedConf.alt} onChange={(e) => setEditedConf({ ...editedConf, alt: e.target.value })} required />
                      <label>Description</label>
                      <textarea  className="inputDescription" type="text" name="description" value={editedConf.description} onChange={(e) => setEditedConf({ ...editedConf, description: e.target.value })} required />
                      <button type="submit">Modifier la conférence</button>
                      <button onClick={closeEditModal}>Annuler</button>
                    </form>
                  </div>
                )}

              </>
            );

            case 'medias':
              return (
                <>
                  <div className="add" onClick={handleToggleForm}>
                    <i className="fa-solid fa-user-plus"></i>
                    <p>Ajouter un media</p>
                  </div>
                  <form className="form-modal" style={{ display: displayForm ? 'flex' : 'none' }} onSubmit={handleAddMedia}>
                    <label>Nom<input type="text" name="nom" value={nomMedia} onChange={(e) => setNomMedia(e.target.value)} required /></label>
                    <label>URL de l'image<input type="text" name="URL" value={imageMediaUrl} onChange={(e) => setImageMediaUrl(e.target.value)} required /></label>
                    <label>Texte alternatif<input type="text" name="alt" value={altMediaText} onChange={(e) => setAltMediaText(e.target.value)} required /></label>
                    <label>Description<input type="text" name="description" value={descriptionMedia} onChange={(e) => setDescriptionMedia(e.target.value)} required /></label>
                    <label>Lien<input type="text" name="lien" value={lienMedia} onChange={(e) => setLienMedia(e.target.value)} required /></label>
                    <label>Titre du lien<input type="text" name="titreLien" value={titreLienMedia} onChange={(e) => setTitreLienMedia(e.target.value)} required /></label>
                    <button className="boutonAjouter" type="submit"> Ajouter à la base de donnée </button>
                    <button onClick={handleToggleForm}> Annuler </button>
                  </form>
                  <div className="conf-modal">
                  {mediaArray.map((media) => (
                      <div className="conf-admin" key={media._id}>
                        <div className="logo-admin-modif">
                          <i className="fa-solid fa-trash-can" onClick={() => deleteMedia(media._id, media.nom)}></i>
                          <i className="fa-solid fa-arrows-up-down-left-right"></i>
                          <i className="fa-solid fa-file-pen" onClick={() => openEditMediaModal(media)}></i>
                        </div>
                        <h4>{media.nom}</h4>
                        <p>{media.description}</p>
                        <img className="img-admin" src={media.image} alt={media.alt} />
                      </div>
                    ))}
                  </div>
            
                  {editModalMediaIsOpen && (
                    <div className="edit-modal">
                      <div className="edit-modal--header">
                        <h1>Modification du média "{editedMedia.nom}"</h1>
                        <i className="fa-regular fa-circle-xmark" onClick={closeEditMediaModal}></i>
                      </div>
                      <form onSubmit={handleUpdateMedia}>
                        <label>Nom</label>
                        <input type="text" name="nom" value={editedMedia.nom} onChange={(e) => setEditedMedia({ ...editedMedia, nom: e.target.value })} required />            
                        <label>URL de l'image</label>
                        <input type="text" name="image" value={editedMedia.image} onChange={(e) => setEditedMedia({ ...editedMedia, image: e.target.value })} required />            
                        <label>Texte alternatif</label>
                        <input type="text" name="alt" value={editedMedia.alt} onChange={(e) => setEditedMedia({ ...editedMedia, alt: e.target.value })} required />            
                        <label>Description</label>
                        <textarea className="inputDescription" type="text" name="description" value={editedMedia.description} onChange={(e) => setEditedMedia({ ...editedMedia, description: e.target.value })} required />            
                        <label>Lien</label>
                        <input type="text" name="lien" value={editedMedia.lien} onChange={(e) => setEditedMedia({ ...editedMedia, lien: e.target.value })} required />            
                        <label>Titre du lien</label>
                        <input type="text" name="titreLien" value={editedMedia.titreLien} onChange={(e) => setEditedMedia({ ...editedMedia, titreLien: e.target.value })} required />            
                        <button type="submit">Modifier le média</button>
                        <button onClick={closeEditMediaModal}>Annuler</button>
                      </form>
                    </div>
                  )}
                </>
              );
            
            case 'gestion de projets':
              return (
                <>
                  <div className="add" onClick={handleToggleForm}>
                    <i className="fa-solid fa-user-plus"></i>
                    <p>Ajouter un projet</p>
                  </div>
                  <form className="form-modal" style={{ display: displayForm ? 'flex' : 'none' }} onSubmit={handleAddProjet}>
                    <label>Nom<input type="text" name="nom" value={nomProjet} onChange={(e) => setNomProjet(e.target.value)} required /></label>
                    <label>URL de l'image<input type="text" name="URL" value={imageProjetUrl} onChange={(e) => setImageProjetUrl(e.target.value)} required /></label>
                    <label>Texte alternatif<input type="text" name="alt" value={altProjetText} onChange={(e) => setAltProjetText(e.target.value)} required /></label>
                    <label>Description<input type="text" name="description" value={descriptionProjet} onChange={(e) => setDescriptionProjet(e.target.value)} required /></label>
                    <label>Lien<input type="text" name="lien" value={lienProjet} onChange={(e) => setLienProjet(e.target.value)} required /></label>
                    <button className="boutonAjouter" type="submit"> Ajouter à la base de donnée </button>
                    <button onClick={handleToggleForm}> Annuler </button>
                  </form>
                  <div className="conf-modal">
                    {projetArray.map((projet) => (
                      <div className="conf-admin" key={projet._id}>
                        <div className="logo-admin-modif">
                          <i className="fa-solid fa-trash-can" onClick={() => deleteProjet(projet._id, projet.nom)}></i>
                          <i className="fa-solid fa-arrows-up-down-left-right"></i>
                          <i className="fa-solid fa-file-pen" onClick={() => openEditProjetModal(projet)}></i>
                        </div>
                        <h4>{projet.description}</h4>
                        <p>{projet.nom}</p>
                        <img className="img-admin" src={projet.image} alt={projet.alt} />
                      </div>
                    ))}
                  </div>
            
                  {editModalProjetIsOpen && (
                    <div className="edit-modal">
                      <div className="edit-modal--header">
                        <h1>Modification du projet "{editedProjet.nom}"</h1> 
                        <i className="fa-regular fa-circle-xmark" onClick={closeEditProjetModal}></i>
                      </div>
                      <form onSubmit={handleUpdateProjet}>
                        <label>Nom</label>
                        <input type="text" name="nom" value={editedProjet.nom} onChange={(e) => setEditedProjet({ ...editedProjet, nom: e.target.value })} required />            
                        <label>URL de l'image</label>
                        <input
                          type="text" name="image" value={editedProjet.image} onChange={(e) => setEditedProjet({ ...editedProjet, image: e.target.value })} required />            
                        <label>Texte alternatif</label>
                        <input type="text" name="alt" value={editedProjet.alt} onChange={(e) => setEditedProjet({ ...editedProjet, alt: e.target.value })} required />            
                        <label>Description</label>
                        <textarea  className="inputDescription" type="text" name="description" value={editedProjet.description} onChange={(e) => setEditedProjet({ ...editedProjet, description: e.target.value })} required />            
                        <label>Lien</label>
                        <input type="text" name="lien" value={editedProjet.lien} onChange={(e) => setEditedProjet({ ...editedProjet, lien: e.target.value })} required />            
                        <button type="submit">Modifier le projet</button>
                        <button onClick={closeEditProjetModal}>Annuler</button>
                      </form>
                    </div>
                  )}
                </>
              );
            
            case 'rédactions scientifiques':
              return (
                <>
                  <div className="add" onClick={handleToggleForm}>
                    <i className="fa-solid fa-user-plus"></i>
                    <p>Ajouter un article</p>
                  </div>
                  <form className="form-modal" style={{ display: displayForm ? 'flex' : 'none' }} onSubmit={handleAddRedaction}>
                    <label>Nom<input type="text" name="nom" value={nomRedaction} onChange={(e) => setNomRedaction(e.target.value)} required /></label>
                    <label>URL de l'image<input type="text" name="URL" value={imageRedactionUrl} onChange={(e) => setImageRedactionUrl(e.target.value)} required /></label>
                    <label>Texte alternatif<input type="text" name="alt" value={altRedactionText} onChange={(e) => setAltRedactionText(e.target.value)} required /></label>
                    <label>Description<input type="text" name="description" value={descriptionRedaction} onChange={(e) => setDescriptionRedaction(e.target.value)} required /></label>
                    <label>Lien<input type="text" name="lien" value={lienRedaction} onChange={(e) => setLienRedaction(e.target.value)} required /></label>
                    <button className="boutonAjouter" type="submit"> Ajouter à la base de donnée </button>
                    <button onClick={handleToggleForm}> Annuler </button>
                  </form>
                  <div className="conf-modal">
                    {redactionArray.map((redaction) => (
                      <div className="conf-admin" key={redaction._id}>
                        <div className="logo-admin-modif">
                          <i className="fa-solid fa-trash-can" onClick={() => deleteRedaction(redaction._id, redaction.nom)}></i>
                          <i className="fa-solid fa-arrows-up-down-left-right"></i>
                          <i className="fa-solid fa-file-pen" onClick={() => openEditRedactionModal(redaction)}></i>
                        </div>
                        <h4>{redaction.description}</h4>
                        <p>{redaction.nom}</p>
                        <img className="img-admin" src={redaction.image} alt={redaction.alt} />
                      </div>
                    ))}
                  </div>

                  {editModalRedactionIsOpen && (
                      <div className="edit-modal">
                        <div className="edit-modal--header">
                          <h1>Modification de la rédaction "{editedRedaction.nom}"</h1> 
                          <i className="fa-regular fa-circle-xmark" onClick={closeEditRedactionModal}></i>
                        </div>
                        <form onSubmit={handleUpdateRedaction}>
                          <label>Nom</label>
                          <input type="text" name="nom" value={editedRedaction.nom} onChange={(e) => setEditedRedaction({ ...editedRedaction, nom: e.target.value })} required />
                          <label>URL de l'image</label>
                          <input
                            type="text" name="image" value={editedRedaction.image} onChange={(e) => setEditedRedaction({ ...editedRedaction, image: e.target.value })} required />
                          <label>Texte alternatif</label>
                          <input type="text" name="alt" value={editedRedaction.alt} onChange={(e) => setEditedRedaction({ ...editedRedaction, alt: e.target.value })} required />
                          <label>Description</label>
                          <textarea  className="inputDescription" type="text" name="description" value={editedRedaction.description} onChange={(e) => setEditedRedaction({ ...editedRedaction, description: e.target.value })} required />
                          <label>Lien</label>
                          <input type="text" name="lien" value={editedRedaction.lien} onChange={(e) => setEditedRedaction({ ...editedRedaction, lien: e.target.value })} required />
                          <button type="submit">Modifier la rédaction</button>
                          <button onClick={closeEditRedactionModal}>Annuler</button>
                        </form>
                      </div>
                    )}
                </>
              );

      default:
        return null;
    }
  };

  //______________________________________________________
  // GESTION DE LA CONNEXION
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  // Vérifie si le token existe dans le localStorage et si l'userID correspond
  const isAuthenticated = token && (userId === '646b9ae57bfc1a70bf332921' || userId === '646b9c217bfc1a70bf332925');
  if (!isAuthenticated) {
    return <Erreur404 />; 
  }

  // Création de la page
  return (
    <div id="pageDashboard">
      <h3>Quel éléments souhaites-tu modifier ? </h3>
      <div className="boutons-admin">
        <button className="btn-admin btn-logo" id="adminLogo" onClick={() => openModal('logos des partenaires')}>
          Logos des partenaires
        </button>
        <button className="btn-admin btn-conf" id="adminConf" onClick={() => openModal('conférences')}>
          Catalogue de conférences
        </button>
        <button className="btn-admin btn-medias" id="adminMedias" onClick={() => openModal('medias')}>
          Apparitions dans les médias
        </button>
        <button className="btn-admin btn-gestion" id="adminGestion" onClick={() => openModal('gestion de projets')}>
          Gestion de projets
        </button>
        <button className="btn-admin btn-redaction" id="adminRedaction" onClick={() => openModal('rédactions scientifiques')}>
          Rédactions scientifiques
        </button>
      </div>
      <p className="deconnexion" onClick={handleLogout}>Se déconnecter</p>

      <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div className="header-admin">
            <h1>Mode administrateur : {selectedModal}</h1>
            <i className="fa-regular fa-circle-xmark" onClick={closeModal}></i>
        </div>
        {renderModalContent()}
      </ReactModal>
    </div>
  );
}