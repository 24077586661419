import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des logos
export async function getLogoData() {
  try {
    const response = await axios.get(`${apiUrl}logo`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données du logo :",
      error
    );
    return [];
  }
}

// Supprimer un logo
export async function deleteLogoData(_id) {
  try {
    await axios.delete(`${apiUrl}logo/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// Ajouter un logo
export async function createLogoData(logoData) {
  try {
    const response = await axios.post(`${apiUrl}logo`, logoData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du logo :", error);
    throw error;
  }
}

// Modifier l'ordre des logos
export async function modifyLogoOrderData(logoOrderData) {
  console.log("Données envoyées au back :", logoOrderData);
  try {
    await axios.patch(`${apiUrl}logo`, logoOrderData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (error) {
    console.error(
      "Erreur lors de la modification de l'ordre des logos :",
      error
    );
    return false;
  }
}
