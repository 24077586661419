// Import des pages de style 
import "./footer.css";

import { Link } from "react-router-dom";

// Création de la fonction composant du footer
function Footer() {
  return (
    <footer>
        <section className="footer_general">
            <p className="RS">Me retrouver sur les réseaux sociaux</p>
            <div className="reseaux_sociaux">
                <Link to="http://www.twitter.com/astropierre" target="_blank" alt="logo twitter"><i className="fa-brands fa-square-twitter"alt="logo twitter"></i><span className="displayNone">Twitter</span></Link>
                <Link to="https://www.linkedin.com/in/pierre-henriquet-41852025a/"target="_blank" alt="logo linkedIn"><i className="fa-brands fa-linkedin"alt="logo linkedIn"></i><span className="displayNone">LinkedIn</span></Link>
            </div>
        </section>

        <div className="Alizaryana">
            <p className="signature"> Site créé et développé par <a href="https://alizaryana.com" target="blank"> AlizaryAna</a></p>
        </div>
    </footer>
  );
}

// Exportation du composant
export default Footer;
