// Import du style
import "./redactions.css";
import "./redactionsMobile.css";

// Import des hooks
import { useState, useEffect } from "react";

// Import des données
import { getRedactionData } from "../../utils/axios/axiosRedactions";

// Création de la fonction composant
export default function Redactions() {
  // Création des variables pour le défilement et le compteur d'images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [redactionData, setRedactionData] = useState([]);
  const imageCount = redactionData.length;
  const currentImage = redactionData[currentImageIndex]?.image;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getRedactionData();
        setRedactionData(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données des rédactions :", error);
        setRedactionData([]);
      }
    }

    fetchData();
  }, []);

  // Création de la fonction photo suivante + modulo
  function next() {
    setCurrentImageIndex((currentImageIndex + 1) % imageCount);
  }

  // Création de la fonction photo précédente + modulo
  function previous() {
    setCurrentImageIndex((currentImageIndex - 1 + imageCount) % imageCount);
  }

  return (
    <div className="pageRedaction">
      <h3>Mon expérience dans la rédaction d'articles scientifiques</h3>
      <p>
        Ma formation scientifique dans le domaine des deux infinis (doctorat en Physique nucléaire appliquée à la Médecine
        et diplôme universitaire d’astronomie/astrophysique) me permet de mener une veille scientifique quotidienne et de
        synthétiser les résultats de la recherche en termes simples et compréhensibles au grand public.
      </p>
      <p>
        L’Astronomie est maintenant ma spécialité, mais ma formation me permet de rester à jour, parler et vulgariser
        d’autres domaines gravitant souvent autour de la physique, à différentes échelles (humaine, infiniment petit ou
        grand).
      </p>
      <p>
        J’ai d’ailleurs été, à de multiples reprises, sollicité en tant que référent lors de la rédaction de plusieurs
        articles de vulgarisation scientifique.
      </p>
  
      <div className="carrousel">
        <div className="chevrons">
          <i onClick={previous} className="fa-solid fa-circle-arrow-left"></i>
          <p>Précédent</p>
        </div>
        {redactionData.length > 0 && (
          <div className="box">
            <a className="cardConf cardArticles" href={redactionData[currentImageIndex]?.lien} target="_blank">
              <h4>{redactionData[currentImageIndex]?.nom}</h4>
              <div className="imgBxConf">
                <img src={currentImage} alt={redactionData[currentImageIndex]?.alt} />
              </div>
              <div className="details">
                <p>{redactionData[currentImageIndex]?.description}</p>
              </div>
            </a>
          </div>
        )}
        <div className="chevrons">
          <i onClick={next} className="fa-solid fa-circle-arrow-right"></i>
          <p>Suivant</p>

        </div>
        <div className="chevronsMobile">
            <i onClick={previous} className="fa-solid fa-circle-arrow-left"></i>
            <i onClick={next} className="fa-solid fa-circle-arrow-right"></i>
        </div>
      </div>
    </div>
  );
        }