import { useEffect, useState } from "react";

// Import des données
import {
  getProjetData,
  deleteProjetData,
  createProjetData,
  updateProjetData,
} from "../axios/axiosProjets";

// Import des fonctions génériques
import {
  deleteItem,
  handleAddItem,
  handleUpdateItem,
} from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES PROJETS

export const useProjetState = () => {
  //______________________________________________________
  // Affichage des projets
  const [projetArray, setProjetArray] = useState([]);
  const [editModalProjetIsOpen, setEditModalProjetIsOpen] = useState(false);

  const fetchProjetData = async () => {
    const projetData = await getProjetData();
    setProjetArray(projetData);
  };

  useEffect(() => {
    fetchProjetData();
  }, []);

  //______________________________________________________
  // Suppression d'un projet
  const deleteProjet = async (projetId, projetNom) => {
    deleteItem(
      projetId,
      `le projet ${projetNom}`,
      deleteProjetData,
      setProjetArray
    );
  };

  //______________________________________________________
  // Ajout d'un projet
  const [nomProjet, setNomProjet] = useState("");
  const [imageProjetUrl, setImageProjetUrl] = useState("");
  const [altProjetText, setAltProjetText] = useState("");
  const [descriptionProjet, setDescriptionProjet] = useState("");
  const [lienProjet, setLienProjet] = useState("");

  const handleAddProjet = (e) => {
    handleAddItem(
      e,
      {
        nom: nomProjet,
        image: imageProjetUrl,
        alt: altProjetText,
        description: descriptionProjet,
        lien: lienProjet,
      },
      setProjetArray,
      () => {
        setNomProjet("");
        setImageProjetUrl("");
        setAltProjetText("");
        setDescriptionProjet("");
        setLienProjet("");
      },
      createProjetData
    );
  };

  //______________________________________________________
  // Modification d'un projet
  const [editedProjet, setEditedProjet] = useState({
    nom: "",
    image: "",
    alt: "",
    description: "",
    lien: "",
  });

  const openEditProjetModal = (projet) => {
    setEditedProjet(projet);
    setEditModalProjetIsOpen(true);
  };

  const closeEditProjetModal = () => {
    setEditModalProjetIsOpen(false);
    setEditedProjet({
      nom: "",
      image: "",
      alt: "",
      description: "",
      lien: "",
    });
  };

  const handleUpdateProjet = (e) => {
    handleUpdateItem(
      e,
      editedProjet,
      updateProjetData,
      closeEditProjetModal,
      fetchProjetData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    projetArray,
    setProjetArray,
    deleteProjet,
    nomProjet,
    setNomProjet,
    imageProjetUrl,
    setImageProjetUrl,
    altProjetText,
    setAltProjetText,
    descriptionProjet,
    setDescriptionProjet,
    lienProjet,
    setLienProjet,
    handleAddProjet,
    editModalProjetIsOpen,
    setEditModalProjetIsOpen,
    editedProjet,
    setEditedProjet,
    openEditProjetModal,
    closeEditProjetModal,
    handleUpdateProjet,
  };
};
