import { useEffect, useState } from "react";

// Import des données
import {
  getConfData,
  deleteConfData,
  createConfData,
  updateConfData,
} from "../axios/axiosConfs";

// Import des fonctions génériques
import {
  deleteItem,
  handleAddItem,
  handleUpdateItem,
} from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES CONFERENCES

export const useConfState = () => {
  //______________________________________________________
  // Affichage des conférences
  const [confArray, setConfArray] = useState([]);
  const [editModalConfIsOpen, setEditModalConfIsOpen] = useState(false);

  const fetchConfData = async () => {
    const confData = await getConfData();
    setConfArray(confData);
  };

  useEffect(() => {
    fetchConfData();
  }, []);

  //______________________________________________________
  // Suppression d'une conférence
  const deleteConf = async (confId, confNom) => {
    deleteItem(
      confId,
      `la conférence ${confNom}`,
      deleteConfData,
      setConfArray
    );
  };

  //______________________________________________________
  // Ajout d'une conférence
  const [nomConf, setNomConf] = useState("");
  const [imageConfUrl, setImageConfUrl] = useState("");
  const [altConfText, setAltConfText] = useState("");
  const [descriptionConf, setDescriptionConf] = useState("");

  const handleAddConf = (e) => {
    handleAddItem(
      e,
      {
        nom: nomConf,
        image: imageConfUrl,
        alt: altConfText,
        description: descriptionConf,
      },
      setConfArray,
      () => {
        setNomConf("");
        setImageConfUrl("");
        setAltConfText("");
        setDescriptionConf("");
      },
      createConfData
    );
  };

  //______________________________________________________
  // Modification d'une conférence
  const [editedConf, setEditedConf] = useState({
    nom: "",
    image: "",
    alt: "",
    description: "",
  });

  const openEditModal = (conf) => {
    setEditedConf(conf);
    setEditModalConfIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalConfIsOpen(false);
    setEditedConf({
      nom: "",
      image: "",
      alt: "",
      description: "",
    });
  };

  const handleUpdateConf = (e) => {
    handleUpdateItem(
      e,
      editedConf,
      updateConfData,
      closeEditModal,
      fetchConfData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    confArray,
    setConfArray,
    deleteConf,
    nomConf,
    setNomConf,
    imageConfUrl,
    setImageConfUrl,
    altConfText,
    setAltConfText,
    descriptionConf,
    setDescriptionConf,
    handleAddConf,
    editModalConfIsOpen,
    setEditModalConfIsOpen,
    editedConf,
    setEditedConf,
    openEditModal,
    closeEditModal,
    handleUpdateConf,
  };
};
