import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des projets
export async function getProjetData() {
  try {
    const response = await axios.get(`${apiUrl}projets`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données des projets :",
      error
    );
    return [];
  }
}

// Récupérer les informations d'un projet
export async function getOneProjetData(_id) {
  try {
    const response = await axios.get(`${apiUrl}projets/${_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations du projet :",
      error
    );
    throw error;
  }
}

// Supprimer un projet
export async function deleteProjetData(_id) {
  try {
    await axios.delete(`${apiUrl}projets/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// Ajouter un projet
export async function createProjetData(projetData) {
  try {
    const response = await axios.post(`${apiUrl}projets`, projetData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du projet :", error);
    throw error;
  }
}

// Modifier un projet
export async function updateProjetData(_id, updatedProjectData) {
  try {
    const response = await axios.put(
      `${apiUrl}projets/${_id}`,
      updatedProjectData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du projet :", error);
    throw error;
  }
}
