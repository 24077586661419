// Import des pages
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Accueil from "./pages/Accueil/Accueil";
import QuiSuisJe from "./pages/QuiSuisJe/QuiSuisJe";
import MesPrestations from "./pages/MesPrestations/MesPrestations";
import MesConferences from "./pages/MesConferences/MesConferences";
import Medias from "./pages/Medias/Medias";
import GestionDeProjets from "./pages/GestionDeProjets/GestionDeProjets";
import Redactions from "./pages/Redactions/Redactions";
import MeContacter from "./pages/MeContacter/MeContacter";
import Erreur from "./pages/Erreur/Erreur";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<Accueil />} />
          <Route path="/QuiSuisJe" element={<QuiSuisJe />} />
          <Route path="/MesPrestations" element={<MesPrestations />} />
          <Route
            path="/MesPrestations/Conferences"
            element={<MesConferences />}
          />
          <Route path="/MesPrestations/Medias" element={<Medias />} />
          <Route
            path="/MesPrestations/GestionDeProjets"
            element={<GestionDeProjets />}
          />
          <Route
            path="/MesPrestations/RedactionsScientifiques"
            element={<Redactions />}
          />
          <Route path="/MeContacter" element={<MeContacter />} />
          <Route path="/*" element={<Erreur />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
