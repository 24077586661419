// Import du style
import "./mesPrestations.css";
import "./mesPrestationsMobile.css";

// Import des composants
import { Link } from "react-router-dom";

// Import des données
import dataPrestations from "../../DonneesJSON/Prestations";

export default function MesPrestations() {
  return (
    <div id="pageMesPrestations">
        <h3>Mes prestations</h3>
        <p className="presentationConf"> N'hésitez pas à cliquer sur ces sections pour en savoir plus !</p>
        <div className="box">

        {dataPrestations.map((data) => {
          return (
            <Link to={`/MesPrestations/${data.id}`} className="card" key={data.id}>
              <h4>{data.nom}</h4>
              <div className="imgBx">
                <img src={data.image} alt={data.nom} />
              </div>
              <div className="details" dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </Link>
          );
        })}

        </div>
        
      <p class="phraseFinalePresta">
        Pour tout autre projet que vous souhaitez me soumettre, vous pouvez me
        contacter :{" "}
        <span id="btnMeContacterIci">
          {" "}
          <Link to="/MeContacter"><i className="fa-regular fa-envelope"></i></Link>
        </span>
      </p>
    </div>
  );
}