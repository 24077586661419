// Import des pages de style
import "../MesConferences/mesConferences.css";

// Import des données et besoins
import { getMediaData } from "../../utils/axios/axiosMedias";
import { useState, useEffect } from "react";
import Modal from "react-modal";

// Création de la fonction composant 
export default function Medias() {
  const [mediaData, setMediaData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getMediaData();
        setMediaData(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données des médias :", error);
      }
    }
    fetchData();
  }, []);

  const openModal = (media) => {
    setSelectedMedia(media);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedMedia(null);
    setModalIsOpen(false);
  };

  return (
    <div id="pageMesConferences">
      <h3>Mes interventions dans les médias</h3>
      <p className="presentationConf">
        Que vous soyez à la recherche d’un chroniqueur régulier sur l’actualité scientifique ou que vous ayez besoin d’une intervention ponctuelle sur un sujet spécifique, n’hésitez pas à faire appel à moi.
      </p>

      <div className="boxConf" style={{ filter: modalIsOpen ? "grayscale(100%)" : "none" }}>
        {mediaData.map((media) => (
          <div className="cardConf" onClick={() => openModal(media)} key={media.id}>
            <h4>{media.nom}</h4>
            <div className="imgBxConf">
              <img src={media.image} alt={media.nom} />
            </div>
            <div className="details">
              <p>Cliquez pour plus d'informations</p>
            </div>
          </div>
        ))}
      </div>

      {selectedMedia && (
        <div className="modalBackground" onClick={closeModal}>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="customModal" overlayClassName="customOverlay">
            <i className="fa-regular fa-circle-xmark" onClick={closeModal}></i>
            <h4>{selectedMedia.nom}</h4>
            <img src={selectedMedia.image} alt={selectedMedia.nom} />
            <p>{selectedMedia.description}</p>
            <a className="ecouter" onClick={() => openLinkInNewTab(selectedMedia.lien)}>{selectedMedia.titreLien}</a>
          </Modal>
        </div>
      )}
    </div>
  );
}
