// Import des pages de style
import "./accueil.css";
import "./accueilMobile.css";

// Import des besoins
import React, { useEffect, useState } from "react";
import { getLogoData } from "../../utils/axios/axiosLogo";

// Fonction de la page
export default function Bienvenue() {

  // Récupération des logos
  const [logoArray, setLogoArray] = useState([]);
  useEffect(() => {
    const fetchLogoData = async () => {
      const logoData = await getLogoData();
      setLogoArray(logoData);
    };
    fetchLogoData(); 
  }, []);

  return (
    <div className="pageAccueil">
      <section className="bienvenue">
        <img className="bienvenue_photo" src="images/index/PierreHENRIQUET.jpg" alt="photo de Pierre Henriquet" />
        <div className="bienvenue_text">
          <p className="bonjour">Bonjour et bienvenue.</p>
          <p>Vous trouverez ici toutes les informations sur les services que je propose, ainsi que mon parcours.</p>
        </div>
      </section>

      <section className="présentation">
        <p>Que vous cherchiez un conférencier afin de vous faire voyager parmi les plus belles merveilles de l’Univers, que vous soyez une institution scientifique pour animer un workshop ou une table ronde, une rédaction journalistique ayant besoin d’articles scientifiques ou bien encore un média (radio, télévision, podcast,…) en quête d’interventions au micro, vous êtes au bon endroit.</p>
      </section>

      <div className="interligne"></div>

      <section className="confiance">
        <h3>Ils m'ont fait confiance</h3>
        <div className="confiance_logo">
          {logoArray
            .sort((a, b) => a.order - b.order) // Tri du tableau en utilisant logo.order
            .map((logo) => (
              <img className="logo_box" src={logo.image} alt={logo.alt} key={logo._id} />
            ))}
        </div>
      </section>

    </div>
  );
}
