// Import des pages de style
import "./header.css";
import "./headerMobile.css";

// Import des composants
import { Link } from "react-router-dom";

// Création de la fonction composant du header
function Header() {
  return (
    <header>
      <Link to="/">
        <span  className="header_entete">
          <img className="logo_astropierre" src="/images/logo astropierre.png" alt="Logo Astropierre"/> 
          <h1>Pierre Henriquet</h1>
          <h2>Médiateur scientifique, conférencier et chargé de projets</h2>
        </span>
      </Link>

      <nav>
        <Link to="/QuiSuisJe">
          <span className="btn_nav">
            <i className="fa-solid fa-user-astronaut"></i>
            <h3>Qui suis-je ?</h3>
          </span>
        </Link>

        <Link to="/MesPrestations">
          <span className="btn_nav">
            <i className="fa-solid fa-meteor"></i>
            <h3>Mes prestations</h3>
          </span>
        </Link>

        <Link to="/MeContacter">
          <span className="btn_nav">
              <i className="fa-solid fa-satellite-dish"></i>
              <h3>Me contacter</h3>
          </span>
        </Link>
      </nav>

    </header>
  );
}

// Export de la fonction composant
export default Header;
