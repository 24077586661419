import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des conf
export async function getConfData() {
  try {
    const response = await axios.get(apiUrl + "conf");
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données de la conf :",
      error
    );
    return [];
  }
}

// Récupérer les informations d'une conf
export async function getOneConfData(_id) {
  try {
    const response = await axios.get(`${apiUrl}conf/${_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations de la conf :",
      error
    );
    throw error;
  }
}

// Supprimer une conf
export async function deleteConfData(_id) {
  try {
    await axios.delete(`${apiUrl}conf/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// Ajouter une conf
export async function createConfData(confData) {
  try {
    const response = await axios.post(apiUrl + "conf", confData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la conf :", error);
    throw error;
  }
}

// Modifier une conf
export async function updateConfData(_id, updatedConfData) {
  try {
    const response = await axios.put(`${apiUrl}conf/${_id}`, updatedConfData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de la conf :", error);
    throw error;
  }
}
