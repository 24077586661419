import { useEffect, useState } from "react";

// Import des données
import {
  getLogoData,
  deleteLogoData,
  createLogoData,
} from "../../utils/axios/axiosLogo";

// Import des fonctions génériques
import { deleteItem, handleAddItem } from "./handleFunctionsGeneriques";

//______________________________________________________
// GESTION DES LOGOS

export const useLogoState = () => {
  //______________________________________________________
  // Affichage des logos
  const [logoArray, setLogoArray] = useState([]);

  useEffect(() => {
    const fetchLogoData = async () => {
      const logoData = await getLogoData();
      setLogoArray(logoData);
    };
    fetchLogoData();
  }, []);

  //______________________________________________________
  // Suppression d'un logo
  const deleteLogo = async (logoId, logoAlt) => {
    deleteItem(logoId, `le logo de ${logoAlt}`, deleteLogoData, setLogoArray);
  };

  //______________________________________________________
  // Ajout d'un logo
  // Ajout d'un logo
  const [imageUrl, setImageUrl] = useState("");
  const [altText, setAltText] = useState("");

  const handleAddLogo = (e) => {
    handleAddItem(
      e,
      { image: imageUrl, alt: altText },
      setLogoArray,
      () => {
        setImageUrl("");
        setAltText("");
      },
      createLogoData
    );
  };

  //______________________________________________________
  // Export du rendu du hook
  return {
    logoArray,
    setLogoArray,
    deleteLogo,
    imageUrl,
    setImageUrl,
    altText,
    setAltText,
    handleAddLogo,
  };
};
