// Import des pages de style
import "./quiSuisJe.css";
import "./quiSuisJeMobile.css";

export default function QuiSuisJe (){
    return (
        <div id="pageQuiSuisJe">
        <h3> Qui suis-je ?</h3>

        <article className="enfance">

            <div className="enfance_image">
                <img className="granier" src="images/qui_suis_je/granier.jpg" alt="voie lactée sur le Mont Granier en Savoie" />
                <div className="enfance_text">
                    <p>Ayant passé mon enfance dans les hautes vallées savoyardes, j’ai eu la chance, très tôt, d’admirer certaines des plus belles nuits étoilées que l’on puisse voir en France métropolitaine.</p> 
                    <p>Et c’est certainement de cet émerveillement que me vient cette envie de partager mon enthousiasme pour le monde qui nous entoure, de l’infiniment grand à l’infiniment petit.</p>
                    <p>Cette envie ne m’a jamais quitté depuis.</p>
                </div>
                <div className="legende">
                    <p>Voie lactée sur le Mont Granier (Savoie)</p>
                    <p>Crédit : Alex Meneghin – Club d’astronomie CRABE</p>
                </div>
            </div>     
        </article>

        <article className="etudes1">
            <p>Après un parcours scolaire orienté vers les matières scientifiques, trois années passées en Classes Préparatoires aux Grandes Ecoles d’ingénieur (CPGE), j’ai trouvé ma voie dans le cursus universitaire jusqu’à l’obtention d’un doctorat en Physique Nucléaire appliquée à la Médecine (vous pouvez retrouver <a href="https://theses.hal.science/tel-00712778" target="_blank"> ma thèse ici)</a>.</p>
        </article>

        <article className="etudes2">
            <div className="etudes2_text">
                <p>Au début des années 2000, j’ai travaillé quatre ans au Centre d’Astronomie de Haute-Provence, dans la ville de Saint-Michel L’observatoire. J’y ai découvert le métier de médiateur scientifique, le plaisir de vulgariser l’astronomie et de transmettre ma passion au(x) public(s).</p>
                <p>J’ai eu l'occasion de valider un diplôme universitaire d’astronomie/astrophysique en parallèle de cette expérience professionnelle.</p>
            </div>
            <img className="etudes2_image" src="images/qui_suis_je/Pierre.jpg" alt="Pierre Henriquet" />
        </article>

        <article className="planetarium">
            <img className="planetarium_image" src="images/qui_suis_je/Pierre plané.jpg" alt="Pierre Henriquet au micro du planétarium de Vaulx en Velin" />
            <div className="etudes2_text">
                <p>Après la soutenance de ma thèse en 2011, j’ai intégré le Planétarium de Vaulx-en-Velin en périphérie de Lyon, en tant que médiateur scientifique et chargé de projet. </p>
                <p>J’ai pu y perfectionner mes techniques de vulgarisation et de médiation, mais aussi y découvrir d’autres activités, telles que l’organisation d’évènements, le développement de partenariats institutionnels, et des apparitions régulières dans les médias.</p>
            </div>
        </article>

        <article className="twitter">
            <div className="twitter_text">
                <p>Depuis 2015, j’ai aussi l’immense plaisir de parler de(s) science(s) sur mon compte Twitter @astropierre.</p>
                <p>J’y apporte quotidiennement des nouvelles du monde de la recherche et je me fais l’écho des toutes dernières découvertes en astronomie, mais aussi dans d’autres domaines (aérospatial, médecine, biologie, physique des particules, physique nucléaire…). C’est pour moi une nouvelle manière d’interagir avec un plus large public.</p>
            </div>
            <img className="twitter_image" src="images/qui_suis_je/Pierre Twitter.jpg" alt="Entête de la page Twitter @astropierre" />
        </article>

        <article className="conclusion">
            <img className="nasa_image" src="images\qui_suis_je\stsci-01gq5chesabwmhrf5na60a6mpf_0.png" alt="Webb Unveils Dark Side" />    
            <div className="conclusion_text">
                <p>C’est fort de ce parcours et de toutes ces expériences que je vous propose désormais mes services.</p>
                <p>Que vous souhaitiez en savoir plus sur les mystères de l’Univers ou que vous ayez un projet de culture scientifique à mettre en place, n’hésitez pas à me contacter pour que nous en discutions ensemble.</p>
            </div>
            <div className="legende">
                <p>Image du James Webb Telescop - NASA</p>
            </div>
        </article>

        <article className="france3">
            <img className="france3_image" src="images/qui_suis_je/Pierre france 3.jpg" alt="Pierre Henriquet sur le plateau de France 3 Auvergne Rhone Alpes dans l'émission Vous êtes formidables présentée par Alain Fauritte" />
            <p className="france3_text">Pour en savoir plus sur moi ainsi que mon parcours, n’hésitez pas à regarder le replay de l’émission « Vous êtes formidables » de France 3 où j’ai eu l’immense plaisir d’être invité.</p>
        </article>

    </div>
        
    )
}