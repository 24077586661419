// Import des pages de style
import "../MesConferences/mesConferences.css";

// Import des données et composants
import { getProjetData } from "../../utils/axios/axiosProjets";
import React, { useEffect, useState } from "react";


// Création de la fonction composant de la galerie de projets
export default function MesProjets() {

  const [projets, setProjets] = useState([]);
  useEffect(() => {
    const fetchProjets = async () => {
      try {
        const data = await getProjetData();
        setProjets(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjets();
  }, []);
  


  const openLinkInNewTab = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div id="pageMesProjets">
      <h3>Mes Projets</h3>
      <p className="presentationConf">
        Institutions scientifiques, universités, entreprises, vous avez un
        workshop à animer ? Une table-ronde à mener ? Une soirée
        institutionnelle à valoriser ? Un événement à faire connaître ? </p>
        <p className="presentationConf">
        Contactez-moi et je vous proposerai mon expertise pour co-construire
        votre événement, le préparer avec sérieux et efficacité, et l’animer,
        bien sûr, le jour même.
      </p>
      <div className="boxConf">
      {projets.map((data) => {
        return (
          <div
            className="cardConf"
            key={data.id}
            onClick={() => openLinkInNewTab(data.lien)}
          >
            <h4>{data.nom}</h4>
            <div className="imgBxConf">
              <img src={data.image} alt={data.alt} />
            </div>
            <div className="details">
              <p>{data.description}</p>
            </div>
          </div>
        );
      })}

      </div>
    </div>
  );
}
