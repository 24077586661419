import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des médias
export async function getMediaData() {
  try {
    const response = await axios.get(`${apiUrl}medias`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données des médias :",
      error
    );
    return [];
  }
}

// Récupérer les informations d'un média
export async function getOneMediaData(_id) {
  try {
    const response = await axios.get(`${apiUrl}medias/${_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations du média :",
      error
    );
    throw error;
  }
}

// Supprimer un média
export async function deleteMediaData(_id) {
  try {
    await axios.delete(`${apiUrl}medias/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

// Ajouter un média
export async function createMediaData(mediaData) {
  try {
    const response = await axios.post(`${apiUrl}medias`, mediaData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création du média :", error);
    throw error;
  }
}

// Modifier un media
export async function updateMediaData(_id, updatedMediaData) {
  try {
    const response = await axios.put(
      `${apiUrl}medias/${_id}`,
      updatedMediaData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification du média :", error);
    throw error;
  }
}
