import axios from "axios";
const apiUrl = "https://api.astropierre.com/";

// Récupérer le tableau des rédactions
export async function getRedactionData() {
  try {
    const response = await axios.get(`${apiUrl}redactions`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données des rédactions :",
      error
    );
    return [];
  }
}

// Récupérer les informations d'une rédaction
export async function getOneRedactionData(_id) {
  try {
    const response = await axios.get(`${apiUrl}redactions/${_id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations de la rédaction :",
      error
    );
    throw error;
  }
}

// Supprimer une rédaction
export async function deleteRedactionData(_id) {
  try {
    await axios.delete(`${apiUrl}redactions/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// Ajouter une rédaction
export async function createRedactionData(redactionData) {
  try {
    const response = await axios.post(`${apiUrl}redactions`, redactionData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la rédaction :", error);
    throw error;
  }
}

// Modifier une redaction
export async function updateRedactionData(_id, updatedRedactionData) {
  try {
    const response = await axios.put(
      `${apiUrl}redactions/${_id}`,
      updatedRedactionData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'article :", error);
    throw error;
  }
}
