// FONCTIONS GENERIQUES
//______________________________________________________
// Suppression d'un item
export const deleteItem = async (
  itemId,
  itemNom,
  deleteDataFunction,
  updatedArrayStateSetter
) => {
  try {
    const confirmDelete = window.confirm(`Supprimer ${itemNom} ?`);

    if (confirmDelete) {
      const success = await deleteDataFunction(itemId);
      if (success) {
        const updatedArray = updatedArrayStateSetter((prevArray) =>
          prevArray.filter((item) => item._id !== itemId)
        );
        updatedArray(updatedArray);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

//______________________________________________________
// Ajout d'un item
export const handleAddItem = async (
  e,
  itemData,
  dataArraySetter,
  clearItemData,
  createItemFn
) => {
  e.preventDefault();

  try {
    const createdItem = await createItemFn(itemData);
    dataArraySetter((prevItems) => [...prevItems, createdItem]);
    clearItemData();
  } catch (error) {
    console.error(error);
  }
};

//______________________________________________________
// Modification d'un item
export const handleUpdateItem = async (
  e,
  editedItem,
  updateItemFn,
  closeEditModal,
  fetchDataFn
) => {
  e.preventDefault();

  try {
    const updatedItemData = {
      nom: editedItem.nom,
      image: editedItem.image,
      alt: editedItem.alt,
      description: editedItem.description,
      lien: editedItem.lien,
      titreLien: editedItem.titreLien,
    };

    await updateItemFn(editedItem._id, updatedItemData);

    closeEditModal();
    fetchDataFn();
  } catch (error) {
    console.error(error);
  }
};
